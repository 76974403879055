import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material'; 
import { account, accountGridItemThird } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery'; 
import { useLazyGetEmplsListQuery } from '../../../../store/newApi/users/users.js';
import Preloader from '../../../../components/Preloader/Preloader';
  
import { resetFilter, setFilter } from '../../../../store/rtkSlices/empls.js';
import { useDispatch, useSelector } from 'react-redux';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { FILTER_TYPES } from '../../../../config/filter';
import { Link, useNavigate } from 'react-router-dom';
import { PAGE_EMPL_BY_ID, PAGE_EMPL_CREATE } from '../../../../shared/empls/pageUrls';
import { EmplCard } from '../../../../entities/empl/ui/EmplCard/EmplCard';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplEmpls() {
	const [trigger, { data, isLoading }] = useLazyGetEmplsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const filters = useSelector((state) => state['emplsRtk']?.filter) ?? {};
	const [filtering, setFiltering] = useState(false);
	const navigate = useNavigate();

	// Для режима выбора карточек
	const [selectionMode, setSelectionMode] = useState(false);
	const [selectedCards, setSelectedCards] = useState([]);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 30 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 30, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 30 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	// Обработка выбора карточек
	const handleCardSelect = (id) => {
		setSelectedCards((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((cardId) => cardId !== id);
			} else {
				return [...prevSelected, id];
			}
		});
	};

	const dispatch = useDispatch();

	const handleNewEmpl = () => {
		navigate(PAGE_EMPL_CREATE);
	};

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Сотрудники'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium" onClick={handleNewEmpl}>
						Добавить сотрудника
					</Button>,
				]}

				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'user_group',
								title: 'Должность',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: [
									{ value: 1, label: 'Владелец' },
									{ value: 2, label: 'Администратор' },
									{ value: 3, label: 'Менеджер' },
									{ value: 4, label: 'Менеджер по ремонту' },
									{ value: 5, label: 'Диспетчер' },
									{ value: 6, label: 'Приемосдатчик' },
									{ value: 7, label: 'Ремонтник' },
									{ value: 8, label: 'Охранник' },
									{ value: 9, label: 'Бухгалтер' },
									{ value: 10, label: 'Стажёр' },
								],
							},
							{
								name: 'terminal',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: ['date_created_after', 'date_created_before'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}> 
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemThird(isDesktop)} key={index}>
									<Link to={PAGE_EMPL_BY_ID(card?.id)}> 
										<EmplCard
											name={card?.full_name ? card?.full_name : 'Администратор'}
											login={card?.username}
											terminal={card?.terminals ? card?.terminals[0]?.name : '-'}
											job={card?.terminals ? card?.terminals[0]?.group?.name : '-'}
										/>
									</Link>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplEmpls;
