import { Button } from '@mui/material';
import Header from '../../../../../newComponents/Header/Header';
import styles from './AccountEmplOneGroup.module.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateGroupMutation, useLazyGetGroupsByIdQuery, useUpdateGroupMutation } from '../../../../../store/newApi/groups/groups';
import { JobInputAutocomplete } from '../../../../../newComponents/Form/Fields/JobInputAutocomplete';
import { useLazyGetPermissionsListQuery } from '../../../../../store/newApi/permissions/permissions';
import { getPermissionsMap } from '../AccountEmplOneEmpl/components/TabTerminal/utils';
import InputText from '../../../../../newComponents/Inputs/InputText/InputText';
import { PermissionsTable } from '../../../../../newComponents/PermissionsTable/PermissionsTable';
import { getSelectedPermissionIds } from '../AccountEmplOneEmpl/components/TabTerminal/utils/getSelectedPermissionIds';

export const AccountEmplOneGroup = () => {
	const navigate = useNavigate();
	const { jobId: editedJobId } = useParams();
	const [getEditedJob, { data: editedJobData }] = useLazyGetGroupsByIdQuery();

	const handleGoBackList = () => {
		navigate('/accountEmpl/createJob');
	};

	useEffect(() => {
		if (editedJobId) {
			getEditedJob(editedJobId);
		}
	}, [editedJobId]);

	const [selectedItems, setSelectedItems] = useState({ permissionsMap: {}, disabled: false });
	const [nameJob, setNameJob] = useState('');
	const [baseJob, setBaseJob] = useState(null);
	const [fetchPermissionsList, { data: permissionsList }] = useLazyGetPermissionsListQuery({});
	const [updateGroup] = useUpdateGroupMutation();
	const [createGroup] = useCreateGroupMutation();

	const allSections = useMemo(() => permissionsList?.map((obj) => obj.name), [permissionsList])
	const extendedPermissions = useMemo(() => getPermissionsMap(baseJob?.permissions, allSections), [baseJob, allSections])
	const selectedCheckboxes = useMemo(() => ({ defaultPermissionsSelections: extendedPermissions, selectedItems }), [extendedPermissions, selectedItems]);

	useEffect(() => {
		fetchPermissionsList({});
	}, []);

	useEffect(() => {
		if (editedJobData) {
			setNameJob(editedJobData?.name);
			setSelectedItems(getPermissionsMap(editedJobData?.permissions))
		}
	}, [editedJobData]);

	const handleUpdateJob = () => {
		updateGroup({
			groupId: editedJobId,
			name: nameJob && nameJob,
			permissions: getSelectedPermissionIds(selectedItems.permissionsMap, permissionsList),
		});
	};

	const handleCreateJob = () => {
		createGroup({
			name: nameJob,
			permissions: getSelectedPermissionIds({ ...extendedPermissions.permissionsMap, ...selectedItems.permissionsMap}, permissionsList),
		});
	};

	const handleChoose = (id, jobObj) => {
		setBaseJob(jobObj); 
	};

	const handleSelectItems = useCallback((updatedPermissions) => {
		setSelectedItems({ permissionsMap: updatedPermissions, disabled: false })
	}, []);

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
			<Header text={editedJobId && editedJobData ? `Должность ${editedJobData.name}` : 'Создать должность'} />
			<div className={styles.content}>
				<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
						{editedJobId ? (
							<InputText
								labelText={'Должность'}
								placeholder={'Введите название'}
								value={nameJob}
								onChange={(e) => setNameJob(e.target.value)}
							/>
						) : (
							<>
								<InputText
									labelText={'Название должности'}
									placeholder={'Введите название'}
									value={nameJob}
									onChange={(e) => setNameJob(e.target.value)}
								/>
								<div>
									<label>Взять за основу</label>
									<JobInputAutocomplete value={baseJob?.id} onChange={handleChoose} />
								</div>
							</>
						)}
						{permissionsList && (
							<PermissionsTable
								sections={allSections}
								selectedItems={selectedCheckboxes}
								setSelectedItems={handleSelectItems}
							/>
						)}
					</div>

					<div style={{ justifyContent: 'flex-end', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
						<Button variant="outlined" color="primary" size="medium" onClick={handleGoBackList}>
							Назад
						</Button>
						<Button variant="contained" color="primary" size="medium" onClick={editedJobId ? handleUpdateJob : handleCreateJob}>
							Сохранить
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
