export const header = (isDesktop) => ({
	container: {
		backgroundColor: '#FFFFFF',
		padding: '8px 16px',
		display: 'flex',
		flexDirection: 'column',
		gap: '5px',
		minHeight: isDesktop ? '80px' : '60px',
		justifyContent: 'center'
	},
	title: {
		color: '#343C6A',
		fontSize: isDesktop ? '20px' : '15px',
		fontWeight: 600,
		lineHeight: '24px',
	},
	steps: {
		color: '#343C6A',
		fontSize: '14px',
		lineHeight: '18px',
		fontWeight: 400,
	},
	buttonsContainer: {
		color: '#343C6A',
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
});

export const headerBlock = (isTablet) => {
	return [
		{
			padding: '0 8px 0 0',
			display: 'flex',
			flexDirection: isTablet ? 'row' : 'column',
			alignItems: isTablet ? 'center' : 'flex-start',
			justifyContent: 'space-between',
			alignSelf: 'center'
		},
	];
};
