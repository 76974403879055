import { useCallback, useEffect, useMemo, useState } from "react";
import { InputAutocomplete } from "../../../../../../../newComponents/Inputs/InputAutocomplete/InputAutocomplete"
import ContainerMap from "../../../../components/ReportContainers/steps/ChoiceDamageArea/components/ContainerMap/ContainerMap";
import styles from './InspectionCreateDamageStep.module.scss';
import { CheckBox, Preloader } from "../../../../../../../components";
import { isDetailCheckBoxDisabled } from "../../../../components/ReportContainers/steps/ChoiceDamageArea/components/ContainerMap/Sides/helpers/isDetailCheckBoxDisabled";
import { isMaterialOrDamageChosen } from "../../../../components/ReportContainers/steps/ChoiceDamageArea/components/ContainerMap/Sides/helpers/isMaterialOrDamageChosen";
import { isDetailDamaged } from "../../../../components/ReportContainers/steps/ChoiceDamageArea/components/ContainerMap/Sides/helpers/isDetailDamaged";
import { useUpdateEntryReportMutation } from "../../../../../../../store/newApi/entryreports/entryreports";
import { DamageItemApi, DamagePictureApi, DamageTypeApi, DimesionsApiType } from "../../../../../../../shared/entryreports/types";
import { Button, Typography } from "@mui/material";
import MainInput from "../../../../../../../components/Inputs/MainInput/MainInput";
import InputText from "../../../../../../../newComponents/Inputs/InputText/InputText";
import { v4 as uuidv4 } from 'uuid';
import DescrDamagePhoto from "../../../../components/ReportContainers/steps/DescrDamagePhoto/DescrDamagePhoto";
import { PageFooterActions } from "../../../../../../../shared/ui/PageFooterActions/PageFooterActions";

const data = require('../../../../../../../assets/damages1.json');

const sides = data.container_sides;
// @ts-ignore
const sideOptions = Object.entries(sides).map(([key, value]) => ({ id: key, label: `${value.code} - ${key}` }))

const getSelectedSideForMap = (selectedSideTitle: string) => {
    return Object.entries(sides)
        .map(([key, value]) => ({
            // @ts-ignore
            code: value.code,
            title: key,
            // @ts-ignore
            placement_code_description: value.placement_code_description,
            // @ts-ignore
            components: value.components,
            // @ts-ignore
            details: value.details,
            // @ts-ignore
            damages: value.damages
        }))
        .find((item) => item.title === selectedSideTitle)
}

const getSelectedComponent = ({ selectedSide, option, part }) => {
    const components = selectedSide?.components;
    if (!components) return null;
    return (
        components.find(c => c.assotiations?.find(a => a.option === option && a.part === part))
        || components.find(c => c.assotiations?.find(a => a.option === option && a.parts.includes(part)))
    )
}

type SelectDamageTypeProps = {
    selectedSideDamages: DamageTypeApi[];
    selectedDamages: DamageTypeApi[];
    onSelectDamage: (damage: DamageTypeApi[]) => void;
}

const SelectDamageType = ({ selectedSideDamages = [], selectedDamages, onSelectDamage }: SelectDamageTypeProps) => {
    const handleCheckBoxClick = (label) => {
        if (selectedDamages.map(s => s.name).includes(label.name)) {
            onSelectDamage(selectedDamages.filter(s => s.name !== label.name))
        } else {
            onSelectDamage([...selectedDamages, label])
        }
    };

    const isDamageTypeChoosen = (selectedDamages, damage) => {
        return selectedDamages.map(s => s.name).includes(damage?.name)
    };

    return (
        <>
            <div className={styles.checkbox_items}>
                {selectedSideDamages.map((checkbox, index) => (
                    <div key={index}>
                        <CheckBox
                            type={2}
                            typeCheck={isDamageTypeChoosen(selectedDamages, checkbox)}
                            onClick={() => handleCheckBoxClick(checkbox)}
                        />
                        <p>
                            {checkbox.name} - {checkbox.code}
                        </p>
                    </div>
                ))}
            </div>
        </>
    );
}

export const SelectDamageDetails = ({
    selectedSide,
    setDamagedDetails = (newState) => { },
    setChoosenMaterial = (newState) => { },
    damagesList = [],
    selectedDamageDetails = [],
    choosenMaterial
}) => {
    //! Для выбора детали (левые/правые стороны, перед, торец с дверью)
    const handleCheckBoxOptionClick = (component, option) => {
        const newDamagesDetails = selectedDamageDetails.includes(option.code)
            ? selectedDamageDetails.filter(d => d !== option.code)
            : [...selectedDamageDetails, option.code]

        setDamagedDetails(newDamagesDetails);
    };

    //! Для дополнительного выбора дополнительных данных (крыша/пол 'T', 'B')
    const handleCheckBoxAdditionalClickRequired = (component, option) => {
        setChoosenMaterial(option.name);
    };

    //! Для дополнительного выбора дополнительных данных (Низ 'U')
    const handleCheckBoxAdditionalClickNoRequired = (component, option) => {
        setChoosenMaterial(option.name);
    };

    if (!selectedSide)
        return null;

    return (
        <div className={styles.checkboxMain}>
            {selectedSide.details.length > 0 ? (
                <>
                    <Typography variant="h6">
                        {['T', 'B'].includes(selectedSide.code)
                            ? 'Покрытие'
                            : ['U'].includes(selectedSide.code)
                                ? 'Элементы выбранной зоны'
                                : 'Детали'}
                    </Typography>
                    {selectedSide.details.map((component, index) => (
                        <div key={index} className={styles.checkBox_block}>
                            <h5>{component.name}</h5>
                            <div className={styles.checkBox_items}>
                                {component.options.map((option, index) => (
                                    <div className={styles.checkBox_item} key={index}>
                                        <CheckBox
                                            type={2}
                                            disabled={
                                                ['U'].includes(selectedSide.code)
                                                    ? false
                                                    : ['T', 'B'].includes(selectedSide.code)
                                                        ? selectedSide.damages.length === 0
                                                        : isDetailCheckBoxDisabled(damagesList, selectedDamageDetails, option.code)
                                            }
                                            typeCheck={
                                                ['T', 'B'].includes(selectedSide.code)
                                                    ? isMaterialOrDamageChosen(selectedSide, choosenMaterial, option)
                                                    : ['U'].includes(selectedSide.code)
                                                        ? false
                                                        : isDetailDamaged(damagesList, selectedDamageDetails, option.code)
                                            }
                                            onClick={
                                                ['T', 'B'].includes(selectedSide.code)
                                                    ? () => handleCheckBoxAdditionalClickRequired(component, option)
                                                    : ['U'].includes(selectedSide.code)
                                                        ? () => handleCheckBoxAdditionalClickNoRequired(component, option)
                                                        : () => handleCheckBoxOptionClick(component, option)
                                            }
                                        />
                                        <p>{option.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <></>
            )}
        </div>
    )
}

export type DamageEditionalInfoProps = {
    selectedValues: DimesionsApiType,
    handleChange: (value: DimesionsApiType) => void
}

export const DamageEditionalInfo = ({ selectedValues, handleChange }: DamageEditionalInfoProps) => {
    const handleChangeComment = useCallback((e) => {
        handleChange({
            ...selectedValues,
            comment: e.target.value
        })
    }, [selectedValues, handleChange])

    const handleChangeDepth = useCallback((e) => {
        handleChange({
            ...selectedValues,
            d: e.target.value
        })
    }, [selectedValues, handleChange])

    const handleChangeWidth = useCallback((e) => {
        handleChange({
            ...selectedValues,
            w: e.target.value
        })
    }, [selectedValues, handleChange])

    const handleChangeLength = useCallback((e) => {
        handleChange({
            ...selectedValues,
            l: e.target.value
        })
    }, [selectedValues, handleChange])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
                {/* @ts-ignore */}
                <InputText
                    title={'Длина, мм'}
                    value={selectedValues.l}
                    onChange={handleChangeLength}
                    placeholder={'999'}
                />
                {/* @ts-ignore */}
                <InputText
                    title={'Ширина, мм'}
                    value={selectedValues.w}
                    onChange={handleChangeWidth}
                    placeholder={'999'}
                />
                {/* @ts-ignore */}
                <InputText
                    title={'Глубина, мм'}
                    value={selectedValues.d}
                    onChange={handleChangeDepth}
                    placeholder={'999'}
                />
            </div>
            <div className={styles.textInfo}>
                <textarea
                    name="descrOfDamage"
                    id="comment"
                    className={styles.commentTextArea}
                    onChange={handleChangeComment}
                    placeholder="Укажите дополнительную информацию о повреждении"
                >
                    {selectedValues.comment}
                </textarea>
            </div>
        </div>
    )
}

export type SelectedAreaType = {
    sideIndex: string,
    sideName: string,
    type: string,
    divider: string,
    part: string
}

const INITIAL_DIMENSIONS_STATE = {
    d: "",
    l: "",
    w: "",
    comment: ""
}

export const InspectionCreateDamageStep = ({ entryReportData, isFetching, selectedDamageId, disableEdit = false, onBack }) => {

    const [updateEntryReport, { data: updatedEntryReportData, isSuccess: updateEntryReportSuccess, reset: resetUpdate }] = useUpdateEntryReportMutation()

    // ==== Поля для создания/редактирования повреждения ====
    const [selectedSide, setSelectedSide] = useState('');
    const [selectedAreas, setSelectedAreas] = useState<SelectedAreaType[]>([]);
    const [selectedDamageDetails, setSelectedDamageDetails] = useState([]);
    const [choosenMaterial, setChoosenMaterial] = useState(null);
    const [selectedDamagesType, setSelectedDamagesType] = useState([]);
    const [selectedDimensions, setDimensions] = useState(INITIAL_DIMENSIONS_STATE);
    const [selectedPictures, setSelectedPictures] = useState<DamagePictureApi[]>([]);
    // ==== Поля для создания/редактирования повреждения ====

    const selectedSideObj = useMemo(() => {
        return getSelectedSideForMap(selectedSide)
    }, [selectedSide]);
    const selectedComponentTitle = useMemo(() => {
        if (selectedAreas.length > 0)
            return getSelectedComponent({selectedSide: selectedSideObj, option: selectedAreas[0].divider, part: +selectedAreas[0].part})?.name || null;
        return null;
    }, [selectedAreas, selectedSideObj])
    const damagesList = useMemo(() => entryReportData?.damages || [], [entryReportData])
    const isEditMode = useMemo(() => !!selectedDamageId, [selectedDamageId]);
    const selectedDamage = useMemo<DamageItemApi>(() => damagesList.find((d: DamageItemApi) => d.uuid === selectedDamageId) || {}, [selectedDamageId, damagesList])

    console.log('selectedDamage', selectedDamage);

    // Если редактирование, то взять текущее состояние выбранного повреждения
    useEffect(() => {
        if (selectedDamage) {
            setSelectedSide(selectedDamage.side || "");
            setSelectedAreas(
                selectedDamage.placement?.map(p => ({
                    divider: p.option,
                    part: p.parts,
                    sideIndex: getSelectedSideForMap(selectedDamage.side).code as string,
                    sideName: selectedDamage.side,
                    type: 'mainPanel'
                })) || []
            )
            setSelectedDamagesType(selectedDamage?.damage || [])
            setSelectedDamageDetails(selectedDamage?.details || [])
            setDimensions(selectedDamage?.dimensions || INITIAL_DIMENSIONS_STATE)
            setSelectedPictures((selectedDamage?.pictures || [])[0] || []);
        }
    }, [selectedDamage]);

    const handleSelectDamageArea = useCallback((value) => {
        // const componentTitle = getSelectedComponent({selectedSide: selectedSideObj, option: value.divider, part: +value.part})?.name;
        // console.log(componentTitle, selectedComponentTitle, value)
        // if (!selectedComponentTitle || componentTitle === selectedComponentTitle)
        if (!disableEdit)
            setSelectedAreas(value)
    }, [disableEdit]);

    const handleChangeSide = useCallback((value) => {
        if (!disableEdit) {
            setSelectedSide(value);
            setSelectedAreas([]);
            setSelectedDamageDetails([]);
            setChoosenMaterial(null);
            setDimensions(INITIAL_DIMENSIONS_STATE);
            setSelectedDamagesType([])
        }
    }, [disableEdit]);

    console.log('selectedComponentTitle', selectedComponentTitle);

    // Хрень которая отправляется на создание/обновление повреждения
    const newDamage = useMemo<DamageItemApi>(() => {
        return {
            ...selectedDamage,
            component: [selectedComponentTitle],
            uuid: selectedDamage.uuid || uuidv4(),
            dimensions: selectedDimensions,
            damage: selectedDamagesType,
            placement: selectedAreas?.map(area => ({ parts: area.part, option: area.divider })),
            details: selectedDamageDetails,
            pictures: [selectedPictures],
            side: selectedSide,
        }
    }, [selectedDamage, selectedDimensions, selectedPictures, selectedDamageDetails, selectedDamagesType, selectedAreas, selectedSide, selectedComponentTitle]);

    const handleUpdateDamages = useCallback(() => {
        if (isEditMode) {
            let newDamagesList = damagesList.filter(d => d.uuid !== selectedDamageId);
            newDamagesList = [...newDamagesList, newDamage]
            console.log(newDamagesList);
            updateEntryReport({ id: entryReportData?.id, damages: newDamagesList })
        } else {
            let newDamagesList = [...damagesList, newDamage];
            console.log(newDamagesList);
            updateEntryReport({ id: entryReportData?.id, damages: newDamagesList })
        }
    }, [damagesList, selectedDamageId, entryReportData, newDamage, isEditMode]);

    const handleSetPicture = useCallback((value) => {
        setSelectedPictures([...selectedPictures, { url: value.Location, filename: value.Key }]);
    }, [selectedPictures]);

    useEffect(() => {
        if (updateEntryReportSuccess) {
            onBack();
            resetUpdate();
        }
    }, [updateEntryReportSuccess, onBack, resetUpdate]);

    if (isFetching)
        // @ts-ignore
        return <Preloader bg={'true'} />

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', flexGrow: 1, justifyContent: 'space-between' }}>
            <div style={{ padding: '10px' }}>
                <div>
                    <Typography variant="h6">
                        Выберите сторону
                    </Typography>
                    {/* @ts-ignore */}
                    <InputAutocomplete
                        options={sideOptions}
                        placeholder="Выберите сторону"
                        onChange={handleChangeSide}
                        value={selectedSide}
                    // labelText="Выберите сторону"
                    />
                </div>
                {selectedSideObj?.placement_code_description?.length > 0 && <div>
                    <Typography variant="h6">
                        Отметьте область повреждений
                    </Typography>
                    <ContainerMap
                        setSmthSelected={handleSelectDamageArea}
                        selectedSide={selectedSideObj}
                        entryOneReport={entryReportData}
                        selectedAreas={selectedAreas}
                        selectedDamageDetails={selectedDamageDetails}
                    />
                </div>}
                <div>
                    <SelectDamageDetails
                        selectedSide={selectedSideObj}
                        damagesList={damagesList}
                        selectedDamageDetails={selectedDamageDetails}
                        setDamagedDetails={setSelectedDamageDetails}
                        setChoosenMaterial={setChoosenMaterial}
                        choosenMaterial={choosenMaterial}
                    />
                </div>
                {selectedSide && <div>
                    <Typography variant="h6">
                        Тип повреждения
                    </Typography>
                    <SelectDamageType
                        selectedSideDamages={selectedSideObj?.damages}
                        selectedDamages={selectedDamagesType}
                        onSelectDamage={setSelectedDamagesType}
                    />
                </div>}
                {selectedSide && <div>
                    <Typography variant="h6">
                        Дополнительная информация
                    </Typography>
                    <DamageEditionalInfo
                        selectedValues={selectedDimensions}
                        handleChange={setDimensions}
                    />
                </div>}
                {selectedSide && <div>
                    <Typography variant="h6">
                        Фото повреждения
                    </Typography>
                    <DescrDamagePhoto
                        entryReportId={entryReportData?.id}
                        currentPictures={selectedPictures}
                        setDamageImages={handleSetPicture}
                        disable={disableEdit}
                    />
                </div>}
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Назад',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    }
                ]}
                rightBtns={!disableEdit ? [
                    {
                        text: 'Сохранить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        disabled: selectedPictures.length === 0,
                        onClick: handleUpdateDamages
                    }
                ] : []}
            />
        </div>
    )
}