export const tableComponent = {
	header: {
		backgroundColor: '#F1F3F9',
		color: '#343C6A',

		'div > span > span > svg.MuiTableSortLabel-icon': {
			opacity: '1 !important',
			color: '#343C6A !important',
			fill: '#343C6A !important',
		},

		'div span span.MuiTableSortLabel-root': {
			opacity: '1 !important',
		},
	},

	topToolbar: {
		minHeight: '0',
	},

	tablePaper: {
		backgroundColor: '#F1F3F9',
		borderRadius: '8px',
		boxShadow: 'none',
	},

	tableContainer: {
		maxHeight: 'calc(100vh - 188px)',
		minHeight: 'calc(100vh - 188px)',
	},

	footer: {
		minHeight: 'none',
		backgroundColor: '#F1F3F9',

		'div:nth-of-type(2)': {
			padding: '0px',
		},
	},

	toolbarAlertBanner: {
		position: 'relative',
		'> div': {
			maxWidth: '100% !important',
			position: 'absolute',
			right: '0',
			top: '-1px',
			color: '#343C6A',

			button: {
				display: 'none',
			},
		},
	},
};
