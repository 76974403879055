import React, { useEffect, useState } from 'react';
import styles from './DescrDamagePhoto.module.scss';
import ImageUploading from 'react-images-uploading';
import { useDispatch, useSelector } from 'react-redux';
import { IconPlusCircle } from '../../../../../../../components/Icons';
// import { setDamageImages } from '../../../../../../../store/slices/containerAcceptanceSlice';
import S3Manager from '../../../../../../../helpers/s3storage';
import RViewerJS from 'viewerjs-react';
// import { useParams } from 'react-router-dom';

function DescrDamagePhoto({ entryReportId, currentPictures, setDamageImages, disable }) {
	// const { reportId } = useParams();
	// const { currentSideDamage } = useSelector((state) => state.containerAccept);
	const [images, setImages] = useState([]);

	const dispatch = useDispatch();

	const imageUploader = new S3Manager('damages');

	const onChange = async (imageList, addUpdateIndex) => {
		// setImages(imageList);
		const newImage = imageList[addUpdateIndex];
		if (newImage) {
			try {
				const result = await imageUploader.uploadImage({
					actId: entryReportId,
					file: { name: 'expample.png', data_url: newImage.data_url },
				});
				const photka = await imageUploader.getImage(result.Key);
				await setDamageImages({ Key: result.Key, Location: result.Location, imageSrc: photka });
			} catch (error) {
				console.error('Ошибка загрузки изображения:', error);
			}
		}
	};

    useEffect(() => {
        if (!!currentPictures?.length) {
            const promises = currentPictures?.map(async (img) => imageUploader.getImage(img.filename));
            Promise
                .all(promises)
                .then(res => setImages(res));
        }
    }, [currentPictures]);

	return (
		<div className={styles.mainCont}>
			<ImageUploading
				multiple
				value={images}
				onChange={onChange}
				maxNumber={10}
				dataURLKey="data_url"
				acceptType={['jpg', 'png', 'jpeg']}
			>
				{({ imageList, onImageUpload, isDragging, dragProps }) => (
					<>
						{/* <div className={styles.selectedPhotos}>
							{images.map((image, index) => (
								<div key={index} className={styles.imageItem} style={{ backgroundImage: `url(${image.imageSrc})` }}></div>
							))}
						</div> */}
						<RViewerJS className={styles.viewer}>
							{images?.map((image, index) => (
								<div key={`image_wrapper_${index}`} className={styles.onePhotoCard}>
									<img src={image} alt="example" />
								</div>
							))}
						</RViewerJS>
						{!disable && <div
							className={`${styles.uploadImageWrapper} ${styles.photoContainer} ${isDragging ? styles.dragging : ''}`}
							{...dragProps}
						>
							<button className={styles.uploadButton} onClick={onImageUpload}>
								Загрузить фото
								<IconPlusCircle color={'primary'} size={'large'} />
							</button>
						</div>}
					</>
				)}
			</ImageUploading>
		</div>
	);
}

export default DescrDamagePhoto;
