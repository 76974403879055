import { Typography } from "@mui/material";
import DescrDamagePhoto from "../../../../components/ReportContainers/steps/DescrDamagePhoto/DescrDamagePhoto";
import { Preloader } from "../../../../../../../components";
import { PageFooterActions } from "../../../../../../../shared/ui/PageFooterActions/PageFooterActions";
import { ENTRYREPORT_STATUSES } from "../../../../../../../shared/entryreports/constants";

export const InspectionPhotoStep = ({
    entryReportData,
    isFetching,
    onBack,
    onAddPictures,
    selectedPictures,
    handleFinish,
    disableEdit = false
}) => {
    if (isFetching)
        // @ts-ignore
        return <Preloader bg={'true'} />

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
            <div style={{ padding: '10px' }}>
                <Typography variant="h6">
                    Фотофиксация осмотра
                </Typography>
                {selectedPictures.length === 0 && 'Нет фото'}
                <DescrDamagePhoto
                    entryReportId={entryReportData?.id}
                    currentPictures={selectedPictures}
                    setDamageImages={onAddPictures}
                    disable={disableEdit}
                />
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Назад',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    }
                ]}
                rightBtns={!disableEdit ? [
                    {
                        text: 'Сохранить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        disabled: selectedPictures.length === 0,
                        onClick: () => handleFinish(ENTRYREPORT_STATUSES.DRAFT.id)
                    }
                ] : []}
            />
        </div>
    )
}