import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isClient } from "../isClient";

export const useGetPermissions = () => {
    // @ts-ignore
    const userData = useSelector((state) => state.me.userData);

    const terminalData = useMemo(() => (userData?.terminals || [])[0], [userData])

    const isRemontnik = useMemo(() =>
        terminalData?.group === 'Ремонтник', [terminalData]);

    return {
        isRemontnik,
        isClient: isClient(),
    };
}