 import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import { getValidatedFields } from '../../../shared/api/getValidatedFields.js';
import api from '../../api.js';

export const users = api.injectEndpoints({
	endpoints: (builder) => ({
		getEmplsList: builder.query({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/erp/users/?type=1&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getUserById: builder.query({
			query: (userId) => { 

				return {
					url: `/api/erp/users/${userId}`,
					method: 'GET',
				};
			},
		}),
		updateStaff: builder.mutation({
			query: ({ userId, ...payload }) => { 
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/erp/staff/${userId}/update/`,
					method: 'PUT',
					body: { 
						...validatedData
					},
				}
			}
		}),
		createStaff: builder.mutation({
			query: ({ ...payload }) => { 
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/erp/staff/create/`,
					method: 'POST',
					body: { 
						...validatedData
					},
				}
			}
		}),
	}),
});

export const { useGetEmplsListQuery, useLazyGetEmplsListQuery, useLazyGetUserByIdQuery, useUpdateStaffMutation, useCreateStaffMutation } = users;
