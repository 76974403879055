import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyGetRepairGroupByIdQuery, useUpdateRepairGroupMutation } from "../../../../../../../store/newApi/service/service";
import S3Manager from "../../../../../../../helpers/s3storage";
import RViewerJS from "viewerjs-react";
import ImageUploading from 'react-images-uploading';
import { UniversalButton } from '../../../../../../../components';
import { IconPlus, IconTrash } from '../../../../../../../components/Icons';
import { format } from 'date-fns';

import styles from './ChooseServicesStep.module.css'

export const RepairPhotoFix = ({ repairGroupId, disabled, handleSetPhotoFix }) => {

    const [images, setImages] = useState([]);

    const [updateRepairGroup, { data: updatedRepairGroup, isSuccess, reset: resetUpdate }] = useUpdateRepairGroupMutation();
    const [fetchRepairGroup, { data: repairGroupData }] = useLazyGetRepairGroupByIdQuery();

    const imageUploader = useMemo(() => new S3Manager('repairFix'), [])
    const fixPictures = useMemo(() => repairGroupData?.fix_pictures || {} || [], [repairGroupData])
    const fixPicturesArr = useMemo(() => Object.entries(fixPictures) || [], [fixPictures])

    useEffect(() => {
        if (isSuccess) {
            fetchRepairGroup({ id: repairGroupId })
            resetUpdate()
        }
    }, [isSuccess, repairGroupId, fetchRepairGroup, resetUpdate]);

    useEffect(() => {
        fetchRepairGroup({ id: repairGroupId })
    }, [fetchRepairGroup, repairGroupId]);

    useEffect(() => {
        handleSetPhotoFix(fixPicturesArr)
    }, [handleSetPhotoFix, fixPicturesArr])

    useEffect(() => {
        setImages([]);
        if (!!fixPicturesArr?.length) {
            fixPicturesArr.forEach(([date, imgs]) => {
                // @ts-ignore
                const promises = imgs?.map(async (img) => imageUploader.getImage(img));
                Promise
                    .all(promises)
                    .then(res => setImages((state) => [...state, [date, res]]));
            })
        }
    }, [fixPicturesArr, imageUploader]);

    const handleSaveImages = useCallback((image) => {
        const createdAt = format(new Date(), 'dd-MM-yyyy');
        updateRepairGroup({
            id: repairGroupId, fix_pictures: {
                ...fixPictures,
                [`${createdAt}`]: [...(fixPictures[createdAt] || []), image.filename]
            }
        })
    }, [repairGroupId, updateRepairGroup, fixPictures])

    const handleRemovePic = useCallback((date, pickIndex) => {
        const filteredPicks = fixPictures[date].filter((item, index) => index !== pickIndex);
        let updatedPicks = {
            ...fixPictures,
        }
        if (filteredPicks.length > 0)
            updatedPicks = {...updatedPicks, [date]: filteredPicks}
        else
            delete updatedPicks[date]

        updateRepairGroup({
            id: repairGroupId,
            fix_pictures: updatedPicks
        });
    }, [fixPictures, updateRepairGroup, repairGroupId]);

    const onChange = async (imageList, addUpdateIndex) => {
        const newImage = imageList[addUpdateIndex];
        if (newImage) {
            try {
                const result = await imageUploader.uploadImage({
                    actId: repairGroupId,
                    file: { name: 'expample.png', data_url: newImage.data_url },
                });
                const photka = await imageUploader.getImage(result.Key);
                console.log(result);
                handleSaveImages({ filename: result.Key });
            } catch (error) {
                console.error('Ошибка загрузки изображения:', error);
            }
        }
    };
    return (
        <div>
            {/* @ts-ignore */}
            <ImageUploading
                multiple
                onChange={onChange}
                maxNumber={10}
                dataURLKey="data_url"
                acceptType={['jpg', 'png', 'jpeg']}
            >
                {({ imageList, onImageUpload, isDragging, dragProps }) => (
                    <>

                        {!disabled && <div
                            className={`${styles.uploadImageWrapper} ${styles.photoContainer} ${isDragging ? styles.dragging : ''}`}
                            {...dragProps}
                        >
                            <button className={styles.uploadButton} onClick={onImageUpload}>
                                {'Загрузить фото'}
                                {/* @ts-ignore */}
                                <IconPlus color={'black'} size={'large'} />
                            </button>
                        </div>}
                        {images.length === 0 && <span>Нет добавленных фото</span>}
                        {/* @ts-ignore */}
                        {images.length > 0 && <RViewerJS className={styles.viewer}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                {images.map(([date, imgs], dateIndex) => {
                                    return (
                                        <div key={`image_wrapper_${dateIndex}`} className={styles.onePhotoCard}>
                                            <span>{date}</span>
                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                {imgs.map((i, pickIndex) => {
                                                    return (
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={i} alt="example" />
                                                            {!disabled && <div style={{ position: 'absolute', top: 0, right: 0, background: 'black', borderRadius: '3px' }}>
                                                                <UniversalButton
                                                                    /* @ts-ignore */
                                                                    icon={<IconTrash size={'large'} color={'white'} />}
                                                                    typeButton={'button'}
                                                                    onClick={() => handleRemovePic(date, pickIndex)}
                                                                />
                                                            </div>}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </RViewerJS>}
                    </>
                )}
            </ImageUploading>
        </div>
    )
}